<template>
  <svg
    class="size-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  ><path
    stroke="#fff"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    d="M6 18 18 6M6 6l12 12"
  /></svg>
</template>
