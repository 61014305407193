<template>
  <div
    class="fixed inset-0 z-20 flex h-screen flex-col divide-y divide-gray bg-white pt-12 transition duration-500 lg:hidden"
  >
    <template
      v-for="(item, index) in menuItems"
      :key="index"
    >
      <div v-if="item.scope ? $auth.hasScope(item.scope) : true">
        <BaseMobileMenuSubmenu
          v-if="item.children!.length > 0"
          class="translate-x-full"
          :class="{'translate-x-0': activeSubmenuIndex === index}"
          :item="item"
          :submenu-open="activeSubmenuIndex === index"
          @close-submenu="closeSubmenu"
        />
        <button
          v-if="item.children"
          class="relative block w-full p-4 pr-10 text-left transition hover:bg-info-lighter"
          @click="submenuToggle(index)"
        >
          {{ item.text }}
          <IconsChevron class="absolute right-4 top-1/2 mr-0 -translate-y-1/2 -rotate-90 text-info-light" />
        </button>
        <NuxtLink
          v-else-if="item.url"
          :to="item.url"
          class="relative block p-4 pr-10 transition hover:bg-info-lighter"
          active-class="font-semibold"
        >
          {{ item.text }}
          <IconsChevron class="absolute right-4 top-1/2 mr-0 -translate-y-1/2 -rotate-90 text-info-light" />
        </NuxtLink>
        <template v-else>
          {{ item.text }}
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {useMenu} from '~/composables/menu';

const {menuItems} = useMenu();

const activeSubmenuIndex = ref<number>();

function submenuToggle(index: number) {
  activeSubmenuIndex.value = index;
}

function closeSubmenu() {
  activeSubmenuIndex.value = undefined;
}
</script>
