<template>
  <div class="relative flex flex-col justify-between gap-4 bg-white px-4 py-3 text-xs sm:flex-row md:px-6">
    <svg
      class="absolute bottom-0 right-0 -z-10 w-[400px]"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 397 333"
    ><circle
      cx="274.5"
      cy="274.5"
      r="274.5"
      fill="#D5F0F9"
      opacity=".6"
    /></svg>
    <div class="flex w-full items-center gap-5">
      <img
        src="~/assets/img/footer-logo.png"
        class="w-11"
        alt=""
      >
      <NuxtLink
        class="text-gray-darker underline"
        :to="{name: 'info-contact'}"
      >
        Contact
      </NuxtLink>
    </div>
    <div class="flex w-full items-center gap-5 sm:justify-end">
      <div class="text-gray-darker">
        Onderdeel van:
      </div>
      <a
        href="https://bidfood.nl"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          class="w-full max-w-[6.25rem]"
          src="~/assets/img/bidfood.svg"
          alt="Bidfood"
        >
      </a>
    </div>
  </div>
</template>
