<template>
  <svg
    class="size-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  ><path
    stroke="#fff"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"
    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
  /></svg>
</template>
