<template>
  <div
    class="absolute inset-0 z-30 divide-y divide-gray bg-white pt-12 transition duration-500"
  >
    <button
      class="relative flex w-full items-center gap-4 p-4 pr-10 text-left font-bold transition hover:bg-info-lighter"
      @click="closeSubmenu"
    >
      <IconsArrowBack />
      {{ item.text }}
    </button>
    <template
      v-for="(child, index) in item.children"
      :key="index"
    >
      <NuxtLink
        v-if="child.url"
        :to="child.url"
        class="relative block p-4 pr-10 transition hover:bg-info-lighter"
        active-class="font-semibold"
      >
        {{ child.text }}
        <IconsChevron class="absolute right-4 top-1/2 mr-0 -translate-y-1/2 -rotate-90 text-info-light" />
      </NuxtLink>
      <template v-else>
        {{ child.text }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeSubmenu() {
      this.$emit('closeSubmenu');
    },
  },
};
</script>
