<template>
  <nav class="hidden lg:contents">
    <ul class="font-base flex items-center gap-2.5">
      <template v-for="(item, index) in menuItems">
        <li
          v-if="item.scope ? $auth.hasScope(item.scope) : true"
          :key="index"
          :class="{'peer': item.children}"
          class="group flex h-full items-center text-base lg:text-sm xl:text-base"
        >
          <NuxtLink
            v-if="item.url"
            :to="item.url.startsWith('/') ? item.url : `/${item.url}`"
            class="peer flex h-full items-center p-2.5 group-hover:font-semibold group-hover:underline"
            exact-active-class="underline decoration-2 underline-offset-2 font-semibold"
            :active-class="item.children ? 'font-semibold' : ''"
            style="text-underline-offset: 6px; text-decoration-thickness: 2px"
          >
            {{ item.text }}
          </NuxtLink>
          <template v-else>
            {{ item.text }}
          </template>
          <IconsChevron
            v-if="item.children?.length"
            class="!-ml-2 transition group-hover:rotate-180"
          />
          <ul
            class="pointer-events-none absolute top-[4.375rem] z-20 divide-y divide-gray rounded-b-xl bg-white py-2 text-gray-darker opacity-0 shadow-lg transition group-hover:pointer-events-auto group-hover:opacity-100"
          >
            <template v-for="(child, k) in item.children ?? []">
              <li
                v-if="child.scope ? $auth.hasScope(child.scope) : true"
                :key="k"
              >
                <NuxtLink
                  v-if="child.url"
                  :to="child.url.startsWith('/') ? child.url : `/${child.url}`"
                  class="flex items-center justify-between gap-4 p-4 transition hover:bg-secondary-light/20"
                  active-class="font-semibold"
                >
                  {{ child.text }}
                  <IconsChevron class="!-ml-2 mr-0 -rotate-90 text-info-light" />
                </NuxtLink>
                <a
                  v-else
                  :href="child.url"
                  target="_blank"
                  rel="noopener nofollow"
                  class="flex items-center justify-between gap-4 p-4 transition hover:bg-info-lighter"
                >
                  {{ child.text }}
                  <IconsChevron class="!-ml-2 mr-0 -rotate-90 text-info-light" />
                </a>
              </li>
            </template>
          </ul>
        </li>
      </template>
      <div
        class="pointer-events-none fixed inset-0 top-12 -z-10 bg-gray-darker opacity-0 transition peer-hover:opacity-10 lg:top-[4.375rem]"
      />
    </ul>
  </nav>
</template>

<script setup lang="ts">
const {menuItems} = useMenu();
</script>
