<template>
  <div class="sticky top-0 z-20">
    <div class="z-30 h-12 bg-primary-dark text-white lg:h-[4.375rem]">
      <div class="flex h-full items-center gap-7 px-4 md:px-6">
        <div class="flex h-full flex-grow gap-4 xl:gap-7">
          <button
            class="block p-2 lg:hidden"
            role="button"
            @click="open = !open"
          >
            <IconsHamburger v-if="open === false" />
            <IconsXMark v-else />
          </button>
          <NuxtLink
            class="h-full flex-shrink-0"
            to="/"
          >
            <img
              class="h-full border border-x-0 border-primary-dark"
              src="~/assets/img/logo-2023.png"
              alt="Bidfood Connect"
            >
          </NuxtLink>

          <BaseNavList />
        </div>
        <div class="relative flex h-full items-stretch text-left">
          <div
            id="user-menu"
            class="group peer flex items-center"
            :aria-expanded="profilePanel"
            aria-haspopup="true"
          >
            <span class="sr-only">Open menu</span>
            <img
              class="size-8 rounded-full border-2 border-white object-cover lg:size-12"
              :src="user.avatar"
              :alt="user.name"
            >
            <IconsChevron class="!mr-0 hidden transition group-hover:rotate-180 lg:block" />

            <div
              class="absolute right-0 top-12 z-20 hidden w-48 origin-top-right rounded-b-md bg-white py-2 text-gray-darker shadow-lg group-hover:block lg:top-[4.375rem]"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <NuxtLink
                :to="{name: 'profile'}"
                class="flex items-center justify-between gap-4 p-4 transition hover:bg-secondary-light/20"
                active-class="font-semibold"
                role="menuitem"
                @click="profilePanel = false"
              >
                Jouw profiel
                <IconsChevron class="mr-0 -rotate-90 text-info-light" />
              </NuxtLink>
              <button
                class="flex w-full items-center justify-between gap-4 p-4 text-left transition hover:bg-secondary-light/20"
                role="menuitem"
                @click="logout"
              >
                Uitloggen
                <IconsChevron class="mr-0 -rotate-90 text-info-light" />
              </button>
            </div>
          </div>
          <div
            class="pointer-events-none fixed inset-0 top-12 -z-10 bg-gray-darker opacity-0 transition peer-hover:opacity-10 lg:top-[4.375rem]"
          />
        </div>
      </div>
    </div>
    <BaseMobileMenu :class="{'-translate-y-full': !open}" />
  </div>
</template>

<script>
export default {
  setup() {
    const {signOut} = useAuth();
    const user = useSofieUser();

    return {
      breadcrumbs: useBreadcrumbsStore(),
      signOut,
      user,
    };
  },
  data() {
    return {
      open: false,
      profilePanel: false,
      hours: this.$moment().format('HH'),
      interval: null,
    };
  },
  watch: {
    $route() {
      this.open = false;
      this.$nextTick(() => {
        this.checkForTitle();

        setTimeout(() => {
          this.checkForTitle();
        }, 100);
        setTimeout(() => {
          this.checkForTitle();
        }, 500);
        setTimeout(() => {
          this.checkForTitle();
        }, 1000);
      });
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.hours = this.$moment().format('HH');
    }, 10000);

    this.checkForTitle();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    async logout() {
      await this.signOut();
      this.profilePanel = false;
    },
    clickOutside() {
      if (this.profilePanel) {
        this.profilePanel = false;
      }
    },
    checkForTitle() {
      const component = this.$refs.page && this.$refs.page.$children[0];

      this.breadcrumbs.title = component?.$metaInfo?.title || null;
    },
  },
};
</script>
