export function useMenu() {
  const role = useUserRole();
  const {menu: baseMenu} = useSofieMenu('Connect');

  const roleMenuName = computed(() => {
    if (role.isSupplier.value) {
      return 'Leverancier';
    } else if (role.isEcommerce.value || role.isConnect.value) {
      return 'Ecommerce';
    } else if (role.isBuyer.value) {
      return 'Inkoper';
    } else if (role.isTraffic.value) {
      return 'Traffic';
    } else {
      return 'Connect'; // Use as fallback as it must not be empty
    }
  });

  const {menu: roleMenuRaw} = useSofieMenu(roleMenuName);
  const roleMenu = computed(() => roleMenuName.value !== 'Connect' ? roleMenuRaw.value : []);

  const {result: lastPostResult} = useLastPostQuery();
  const lastPost = computed(() => lastPostResult.value?.lastPost);

  const {result: lastMagazineResult} = useLastMagazineQuery();
  const lastMagazine = computed(() => lastMagazineResult.value?.lastMagazine);

  return {
    baseMenu,
    roleMenu,
    lastPost,
    lastMagazine,
    menuItems: computed(() => {
      return [
        ...baseMenu.value,
        ...roleMenu.value,
      ];
    }),
  };
}
