<template>
  <svg
    class="size-4"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 17"
  ><path
    fill="currentColor"
    d="M8.3 15.91c-.14.18-.42.18-.6 0L.36 8.57a.4.4 0 0 1 0-.6L7.7.62c.18-.17.46-.17.6 0l.7.67c.18.17.18.46 0 .6L3.55 7.34h12.03c.2 0 .42.2.42.42v.98c0 .25-.21.42-.42.42H3.55L9 14.65c.18.14.18.42 0 .6l-.7.66Z"
  /></svg>
</template>
